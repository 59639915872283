<template>
	<div id="landingpage">
		<section id="slider">
			<slider ref="slider" :options="options">
		        <slideritem >
		        	<img src="../../assets/img/slider/Slide1.webp">
		        	<div class="sliderCopy">
		        		<h3>LOCALIZACIÓN GPS</h3>
		        		<h2>Tu mundo <br><span>siempre <br class="onlyMobile">visible</span></h2>
		        	</div>
		        </slideritem>
		        <slideritem >
		        	<img src="../../assets/img/slider/Slide2.webp">
		        	<div class="sliderCopy">
		        		<h3>LOCALIZACIÓN GPS</h3>
		        		<h2>La tranquilidad de saber <br><span>donde</span></h2>
		        	</div>
		        </slideritem>
		        <slideritem >
		        	<img src="../../assets/img/slider/Slide3.webp">
		        	<div class="sliderCopy">
		        		<h3>LOCALIZACIÓN GPS</h3>
		        		<h2><span>Protege</span> lo que más <br>importa</h2>
		        	</div>
		        </slideritem>
		        <slideritem >
		        	<img src="../../assets/img/slider/Slide4.webp">
		        </slideritem>
		    </slider>
		</section>
		<section id="about">
			<div>
				<span id="spanDecoration"></span>
				<br>
				<h5 id="highlight">LOCALIZADOR GPS SOLE TRACKING</h5>
				<h1>¿Qué es Sole Tracking®?</h1>
				<p><strong>Sole Tracking®</strong> es un servicio de localización por GPS que busca servir como herramienta de seguridad para cuidar de tus familiares, amigos, mascotas o lo que tú quieras. Encuentra en tiempo real de manera segura todo lo que importa.</p>
				<ul>
					<li>
						<h5><img src="../../assets/img/localizaciongps.svg"> Localización GPS</h5>
						<p>Registra cual es la última ubicación en la que se ha encontrado el portador del dispositivo.</p>
					</li>
					<li>
						<h5><img src="../../assets/img/bateria.svg"> Batería</h5>
						<p>Sole Tracking® brinda hasta 18 horas de autonomía, proporcionando entre 7,000 y 8,000 ubicaciones.</p>
					</li>
					<li>
						<h5><img src="../../assets/img/grancobertura.svg"> Gran cobertura</h5>
						<p>En zonas con poca señal satelital ofrece una ubicación aproximada por medio de telemetría de antenas</p>
					</li>
					<li>
						<h5><img src="../../assets/img/interfaz-amigable.svg"> Interfaz amigable</h5>
						<p>Constantemente mejoramos para que la plataforma cubra óptimamente tus necesidades</p>
					</li>
					<li>
						<h5><img src="../../assets/img/multiples-dispositivo.svg"> Multiples dispositivos</h5>
						<p>Podrá registrar todos sus dispositivos en su cuenta, permitiendo el monitoreo de múltiples portadores</p>
					</li>
					<li>
						<h5><img src="../../assets/img/autonomo.svg"> Autónomo</h5>
						<p>No requiere de conexión a smartphones o dispositivos adicionales, lo que le permite funcionar como dispositivo independiente y una conexión exclusiva a nuestros servidores</p>
					</li>
				</ul>
			</div>
			<div>
				<img src="../../assets/img/mujer-celular-gps-localizador.png">
			</div>
		</section>
		<section id="aboutVideo">
			<h2>Conoce Sole Tracking®</h2>
			<video controls width="80%">
			  <source src="" type="video/webm" />
			</video>
			<div id="miniGallery">
				<slider class="contentSlider" ref="slider" :options="optionsMiniGallery" @slide='slide' @tap='onTap' @init='onInit'>
					<slideritem>
						<img src="../../assets/img/gallery/10.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/2.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/12.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/1.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/3.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/4.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/5.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/6.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/7.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/8.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/9.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/11.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/13.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/14.webp">
					</slideritem>
					<slideritem>
						<img src="../../assets/img/gallery/15.webp">
					</slideritem>
				</slider>
			</div>
		</section>
		<section id="howWorks">
			<h2>¿Cómo funciona?</h2>
			<div>
				<article>
					<img src="../../assets/img/cinturon-comofunciona.png">
					<h4>COLOCA TU DISPOSITIVO</h4>
					<p>Manejamos localizadores en forma de cinturón para que el dispositivo sea lo más discreto posible</p>
				</article>
				<article>
					<img src="../../assets/img/conecta-la-app-celular-como-funciona.png">
					<h4>CONECTA LA APP</h4>
					<p>Contamos con una aplicación que de manera intuitiva te permita conectar más de un dispositivo Sole®</p>
				</article>
				<article>
					<img src="../../assets/img/localiza-en-tiempo-real-mapa-como-funciona.png">
					<h4>LOCALIZA EN TIEMPO REAL</h4>
					<p>Mira las últimas ubicaciones en tu celular o computadora</p>
				</article>
			</div>
		</section>
		<section id="forYou">
			<h2>Sole Tracking® es para...</h2>
			<!--<span>Contamos con una aplicación que de manera intuitiva te permitirá conectar más de un dispositivo Sole tracking®</span>-->
			<!--glider-->
			<slider class="contentSlider" ref="slider" :options="optionsForYou" @slide='slide' @tap='onTap' @init='onInit'>
				
				<slideritem>
					<img src="../../assets/img/padres-y-cuidadores-ninos.webp">
					<h5>PADRES Y CUIDADORES</h5>
					<p>Para quienes buscan una manera segura de monitorear la ubicación de sus hijos. Especialmente si son muy jovenes o tienen necesidades especiales. Mantén un ojo protector sobre tus niños cuando están fuera de casa, en la escuela o durante excursiones y actividades al aire libre. ¡Sole Tracking® es para ti!</p>
				</slideritem>
				<slideritem>
					<img src="../../assets/img/familiares-adultos-mayores-localizador-gps.webp">
					<h5>FAMILIARES DE ADULTOS <br>MAYORES</h5>
					<p>Sole Tracking® es para aquellas personas con familiares mayores que podrían ser propensos a desorientarse o sufrir incidentes de salud mientras están solos. Nuestro dispositivo te brinda tranquilidad al poder verificar la ubicación de tus seres queridos en cualquier momento.</p>
				</slideritem>
				<slideritem>
					<img src="../../assets/img/duenos-de-mascotas.webp">
					<h5>PROPIETARIOS DE <br>MASCOTAS</h5>
					<p>Si tu mascota es un miembro de la familia y te preocupas por su seguridad somos tu mejor opción. Sole Tracking® te ayuda a rastrear a mascotas que tienden a escaparse o simplemente para tener la tranquilidad de saber dónde están en todo momento, especialmente si vives en áreas con tráfico intenso o grandes espacios abiertos.</p>
				</slideritem>
				<slideritem>
					<img src="../../assets/img/duenos-de-empresas-repartidor-localizador-rastreo.webp">
					<h5>DUEÑOS DE EMPRESAS</h5>
					<p>Nuestro avanzado sistema de localización te brinda la tranquilidad y el control que necesitas para optimizar tus operaciones. Encuentra en tiempo real tu mercancía y protege tus perdidas ante cualquier percance.</p>
				</slideritem>
				<slideritem>
					<img src="../../assets/img/aventureros-viajeros-localizador-sole.webp">
					<h5>AVENTUREROS Y VIAJEROS</h5>
					<p>Si tú o tu familia son de los que disfrutan de actividades al aire libre y quieren asegurarse de que todos los miembros del grupo, incluidas las mascotas, estén seguros y localizables en caso de separación o emergencia nuestro dispositivo Sole Tracking® llega al rescate. Verás que se convertirá en tu compañero fiel en cada aventura.</p>
				</slideritem>
				<slideritem>
					<img src="../../assets/img/tecnofilos-amantes-de-la-innovacion-gps.webp">
					<h5>TECNÓFILOS Y AMANTES <br>DE LA INNOVACIÓN</h5>
					<p>Si eres amante de lo último en tecnología, innovaciones para mejorar la calidad de vida, eficiencia y seguridad, definitivamente Sole Tracking® es para ti.</p>
				</slideritem>
			</slider>
		</section>
		<section id="plans">
			<h2>Nuestros planes</h2>
			<br>
			<span>Contrata un plan con nosotros y manten seguros a quienes te importan</span>
			<span id="togglebutton">
				<label for="filter" class="switch" aria-label="Toggle Filter">
					<input type="checkbox" id="filter" v-model="planFilter"/>
					<span>Personas</span>
					<span>Mascotas</span>
				</label>
			</span>
			<!--info de personas/mascotas-->
			<div id="personasPlan" class="plansInfo" v-if="planFilter==false">
				<article>
					<span>PARA PERSONAS</span>
					<br>
					<img src="../../assets/img/icono-persona-planes.svg">
					<br>
					<h3>SOLE TRACKING® BÁSICO</h3>
					<!--<h2>$999</h2>-->
					<ul>
						<li>
							<strong>GPS Básico.</strong>
						</li>
						<li>
							<strong>Botón de alerta</strong> discreto a la vista.
						</li>
						<li>
							<strong>Grabación de Audio.</strong>
						</li>
						<li>
							<strong>Alerta Vibratoria</strong> a distancia para localización del dispositivo.
						</li>
						<li>
							<strong>Modo Geocerca</strong> para enviar alertas en áreas donde entre y salga el usuario con su dispositivo.
						</li>
					</ul>
					<h3>OBTENER <br>SOLE TRACKING</h3>
				</article>
				<article>
					<span>PARA PERSONAS</span>
					<br>
					<img src="../../assets/img/icono-persona-planes.svg">
					<br>
					<h3>SOLE TRACKING® ESTÁNDAR</h3>
					<!--<h2>$999</h2>-->
					<ul>
						<li>
							<strong>GPS Avanzado</strong> con la mejor velocidad y precisión.
						</li>
						<li>
							<strong>Botón de alerta</strong> discreto a la vista.
						</li>
						<li>
							<strong>Grabación de Audio.</strong>
						</li>
						<li>
							<strong>Alerta Vibratoria</strong> a distancia para localización del dispositivo.
						</li>
						<li>
							<strong>Modo Geocerca</strong> para enviar alertas en áreas donde entre y salga el usuario con su dispositivo.
						</li>
						<li>
							<strong>Modo Cercanía</strong> para localizar en un rango de 20 metros.
						</li>
						<li>
							<strong>Radio</strong> para localización por radiofrecuencia de 1 kilómetro.
						</li>
						<li>
							<strong>Compatible con localizador.</strong>
						</li>
					</ul>
					<h3>OBTENER <br>SOLE TRACKING</h3>
				</article>
				<article>
					<span>PARA PERSONAS</span>
					<br>
					<img src="../../assets/img/icono-persona-planes.svg">
					<br>
					<h3>SOLE TRACKING® <br>PRO</h3>
					<!--<h2>$999</h2>-->
					<ul>
						<li>
							<strong>GPS Avanzado</strong> con la mejor velocidad y precisión.
						</li>
						<li>
							<strong>Botón de alerta</strong> discreto a la vista.
						</li>
						<li>
							<strong>Grabación de Audio.</strong>
						</li>
						<li>
							<strong>Alerta Vibratoria</strong> a distancia para localización del dispositivo.
						</li>
						<li>
							<strong>Modo Geocerca</strong> para enviar alertas en áreas donde entre y salga el usuario con su dispositivo.
						</li>
						<li>
							<strong>Modo Cercanía</strong> para localizar en un rango de 20 metros.
						</li>
						<li>
							<strong>Radio</strong> para localización por radiofrecuencia de 1 kilómetro.
						</li>
						<li>
							<strong>Compatible con localizador.</strong>
						</li>
						<li>
							Cinturón y<strong> hebilla con mecanismo de seguridad patentado.</strong>
						</li>
						<li>
							<strong>Carcasa reforzada</strong> contra golpes.
						</li>
					</ul>
					<h3>OBTENER <br>SOLE TRACKING</h3>
				</article>
			</div>
			<div id="mascotasPlan" class="plansInfo" v-else>
				<article>
					<span>PARA MASCOTAS</span>
					<br>
					<img src="../../assets/img/icono-mascota-planes.svg">
					<h3>SOLE TRACKING® PET BÁSICO</h3>
					<!--<h2>$999</h2>-->
					<ul>
						<li>
							<strong>GPS Básico.</strong>
						</li>
						<li>
							<strong>Alerta Vibratoria</strong> a distancia para localización del dispositivo.
						</li>
						<li>
							<strong>Modo Geocerca</strong> para enviar alertas en áreas donde entre y salga tu mascota con su dispositivo.
						</li>
					</ul>
					<h3>OBTENER <br>SOLE TRACKING</h3>
				</article>
				<article>
					<span>PARA MASCOTAS</span>
					<br>
					<img src="../../assets/img/icono-mascota-planes.svg">
					<h3>SOLE TRACKING® PET ESTÁNDAR</h3>
					<!--<h2>$999</h2>-->
					<ul>
						<li>
							<strong>GPS Avanzado</strong> con la mejor velocidad y precisión.
						</li>
						<li>
							<strong>Alerta Vibratoria</strong> a distancia para localización del dispositivo.
						</li>
						<li>
							<strong>Modo Geocerca</strong> para enviar alertas en áreas donde entre y salga tu mascota con su dispositivo.
						</li>
						<li>
							<strong>Modo Cercanía</strong> para localizar en un rango de 20 metros.
						</li>
						<li>	
							<strong>Compatible con localizador.</strong>
						</li>
					</ul>
					<h3>OBTENER <br>SOLE TRACKING</h3>
				</article>
				<article>
					<span>PARA MASCOTAS</span>
					<br>
					<img src="../../assets/img/icono-mascota-planes.svg">
					<h3>SOLE TRACKING® PET PRO</h3>
					<!--<h2>$999</h2>-->
					<ul>
						<li>
							<strong>GPS Avanzado</strong> con la mejor velocidad y precisión.
						</li>
						<li>
							<strong>Alerta Vibratoria</strong> a distancia para localización del dispositivo.
						</li>
						<li>
							<strong>Modo Geocerca</strong> para enviar alertas en áreas donde entre y salga el usuario con su dispositivo.
						</li>
						<li>
							<strong>Modo Cercanía</strong> para localizar en un rango de 20 metros.
						</li>
						<li>
							<strong>Radio</strong> para localización por radiofrecuencia de 1 kilómetro.
						</li>
						<li>
							<strong>Compatible con localizador.</strong>
						</li>
						<li>
							Cinturón y<strong> hebilla con mecanismo de seguridad patentado.</strong>
						</li>
						<li>
							<strong>Carcasa reforzada</strong> contra golpes.
						</li>
					</ul>
					<h3>OBTENER <br>SOLE TRACKING</h3>
				</article>
			</div>
		</section>
		<section id="faq">
			<h2>Preguntas frecuentes</h2>
			<br>
			<span>Te mostramos algunas preguntas que probablemente puedas tener</span>
			<!--Acordeones pregunta-respuesta-->
			<div>
				<article>
					<button v-on:click="accordion.q0 = !accordion.q0">¿Quién tiene acceso a la ubicación de mi dispositivo? 
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
							</svg>
						</span>
					</button>
					<p v-bind:class="accordion.q0 === true ? 'active':''">La ubicación de tu dispositivo es accesible únicamente desde tu cuenta por lo que solo tú o a quien le compartas tus credenciales, serán los únicos que tendrán acceso a tú ubicación. Dentro de nuestro sistema podrás crear cuentas adicionales con quienes podrás compartir la ubicación de tu dispositivo con permisos limitados. </p>
				</article>
				<article>
					<button v-on:click="accordion.q1 = !accordion.q1">¿Para que es la suscripción anual?
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
							</svg>
						</span>
					</button>
					<p v-bind:class="accordion.q1 === true ? 'active':''">La suscripción anual asegura el servicio de tus dispositivos durante todo el año, esto incluye: La conexión de red móvil, mantenimiento y conexión del servidor, funcionalidad de nuestra app y soporte técnico</p>
				</article>
				<!--<article>
					<button v-on:click="accordion.q2 = !accordion.q2">¿Mi dispositivo funciona en el extranjero?
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
							</svg>
						</span>
					</button>
					<p v-bind:class="accordion.q2 === true ? 'active':''"></p>
				</article>-->
				<article>
					<button v-on:click="accordion.q3 = !accordion.q3">¿Qué sucede si mi dispositivo deja de funcionar?
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
							</svg>
						</span>
					</button>
					<p v-bind:class="accordion.q3 === true ? 'active':''">Sí consideras que tu dispositivo dejó de funcionar, por favor ponte en contacto con nosotros para poder buscar una solución, puedes comunicarte por medio del formulario de está página o envía un correo a <a href="mailto: contacto@soletracking.com"> contacto@soletracking.com</a> </p>
				</article>
				<article>
					<button v-on:click="accordion.q4 = !accordion.q4">¿Mi dispositivo funciona si no pago la suscripción anual?
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
							</svg>
						</span>
					</button>
					<p v-bind:class="accordion.q4 === true ? 'active':''">Debido a que gran parte de las funcionalidades de los dispositivos Sole Tracking® dependen de conexiones de red, no funcionará si no se ha realizado dicho pago de suscripción </p>
				</article>
				<!--<article>
					<button v-on:click="accordion.q4 = !accordion.q4">¿Cómo funciona nuestro GPS?
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
							</svg>
						</span>
					</button>
					<p v-bind:class="accordion.q4 === true ? 'active':''">Nuestro GPS utiliza tecnología satelital para rastrear la ubicación en tiempo real. A través de nuestra plataforma, puedes ver la ubicación exacta del dispositivo desde tu celular, tablet o computadora.</p>
				</article>
				<article>
					<button v-on:click="accordion.q4 = !accordion.q4">¿Mi dispositivo funciona si no pago la suscripción anual?
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
							</svg>
						</span>
					</button>
					<p v-bind:class="accordion.q4 === true ? 'active':''">Debido a que gran parte de las funcionalidades de los dispositivos Sole Tracking® dependen de conexiones de red, no funcionará si no se ha realizado dicho pago de suscripción </p>
				</article>-->
				<!--<article>
					<button v-on:click="accordion.q5 = !accordion.q5">¿Qué métodos de pago aceptan?
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
							</svg>
						</span>
					</button>
					<p v-bind:class="accordion.q5 === true ? 'active':''"></p>
				</article>-->
			</div>
		</section>
		<section id="stores">
			<h2>Descarga nuestra app</h2>
			<ul>
				<li>
					<a href="https://apps.apple.com/mx/app/sole-tracking/id1645201784" target="_blank">
						<img src="../../assets/img/appstore.png">
					</a>
				</li>
				<li>
					<a href="https://play.google.com/store/apps/details?id=com.soletracking.sole" target="_blank">
						<img src="../../assets/img/googleplay.png">
					</a>
				</li>
			</ul>
		</section>
		<section id="contact">
			<h2>Conecta con nosotros</h2>
			<br>
			<span>Si tienes dudas o sugerencias acerca de nuestro producto ponte en contacto con nosotros, un asesor se comunicará contigo lo antes posible.</span>
			<br>
			<form>
				<div>
					<input type="text" name="nombre" placeholder="NOMBRE" v-model="form.nombre">
				</div>
				<div>
					<input type="text" name="asunto" placeholder="ASUNTO" v-model="form.asunto">
				</div>
				<div>
					<input type="text" name="telefono" placeholder="TELÉFONO" v-model="form.telefono">
				</div>
				<div>
					<input type="text" name="correo" placeholder="CORREO ELECTRÓNICO" v-model="form.correo">
				</div>
				<div id="msgbox">
					<textarea name="mensaje" placeholder="ESCRIBA SU MENSAJE" v-model="form.mensaje"></textarea>
				</div>
				
				<button type="button" @click="sendContactForm()">SOLICITAR INFORMACIÓN</button>
				
			</form>
			<h2 v-if="send.length > 0">{{send}}</h2>
		</section>
	</div>
</template>

<script type="text/javascript">
import { slider, slideritem } from 'vue-concise-slider'
	export default {
	name: 'principal',
	data() {
		return {
			form: {
				nombre: "",
				correo: "",
				asunto: "",
				mensaje: "",
				telefono: ""
			},
			send: "",
			user: {},
			pages:[
	          {
	          html: 'slider1',
	          style: {
	            'background': '#1bbc9b'
	            }
	          },
	          {
	            html: 'slider2',
	            style: {
	              'background': '#4bbfc3'
	            }
	          },
	          {
	            html: 'slider3',
	            style: {
	              'background': '#7baabe'
	            }
	          }
	        ],
	        //Sliding configuration [obj]
	        options: {
	          currentPage: 0,
	         
	          autoplay:4000,
	          loop:true,
	          direction:'horizontal',
	          loopedSlides:1,
	          slidesToScroll:1,
	          timingFunction: 'ease',
	          speed: 300,
	          pagination: false,
	          infinite: 3
	        },

	        optionsForYou:{
	        	currentPage: 0,
	          	
	          	autoplay:5000,
	          	loop:true,
	          	direction:'horizontal',
	          	loopedSlides:1,
	          	slidesToScroll:1,
	          	timingFunction: 'ease',
	          	speed: 300,
	          	pagination: false,
	          	infinite: 3
	        },

	        optionsMiniGallery:{
	        	currentPage: 0,
	          	
	          	autoplay:5000,
	          	loop:true,
	          	direction:'horizontal',
	          	loopedSlides:1,
	          	slidesToScroll:1,
	          	timingFunction: 'ease',
	          	speed: 300,
	          	pagination: false,
	          	infinite: 3,
	          	renderPagination: (h, index) => {
				    return h('div', {
				      class: 'swiper-pagination-bullet'
				    }, [index])
				},
	        },

	        planFilter: false,
	        accordion: {
	        	q0: false,
	        	q1: false,
	        	q2: false,
	        	q3: false,
	        	q4: false,
	        	q5: false,
	        },
		}
	},
	mounted: function(){
        this.currentUser();
    },
    components: {
        slider,
        slideritem
    },

    computed:{
    	
    },

	methods: {

		sendContactForm : function() {
			var instance = this;

			axios.request({
				url: '/api/contacto',
				method: 'post',
				data: instance.form
			}).then(function(response){
				if (response.data.status) {
					instance.form.nombre = "";
					instance.form.correo = "";
					instance.form.asunto = "";
					instance.form.mensaje = "";
					instance.form.telefono = "";
					instance.send = "¡Mensaje Enviado! Nos pondremos en contacto";
				}else {
					console.log("Error!");
				}
			}).catch(function(error){
				console.log("Intenta de nuevo!");
			});
		},

        currentUser: function(){
            let localUser = localStorage.getItem('user');

            if(localUser != null){
                this.user = JSON.parse(localUser);
            }
        },

        slide (data) {
        	console.log(data)
	    },
	    onTap (data) {
	    	console.log(data)
	    },
	    onInit (data) {
	    	console.log(data)
	    }
	}
}
</script>
<style lang="less">

#landingpage{
	padding-top: 120px;

	.slider-item{
		img{
			width: 100%;
		}

		.sliderCopy{
			position: absolute;
		    max-width: 330px;
		    display: flex;
		    flex-direction: column;
		    flex-wrap: nowrap;
		    align-items: center;
		    margin-bottom: 2em;

			h3{
				background-color: #00ACD8;
			    font-size: 21pt;
			    padding: 0.2em 0.5em;
			    font-family: Montserrat-regular;
			}

			h2{
				font-family: Montserrat-medium;
				font-size: 1.7em;

				span{
					font-size: 1em;
					font-family: Montserrat-medium;

					background: linear-gradient(90deg, #00ACD8, #CB007B);
				    color: transparent;
				    background-clip: text;
				}
			}
		}
	}

	.onlyMobile{
		display: none;
	}

	#about{
		display: flex;
	    flex-wrap: nowrap;
	    align-content: center;
	    justify-content: center;
	    align-items: flex-end;
	    padding: 8em 13vw 0em;
	    background-color: #E8E8E8;

	    div{
	    	width: 50%;

	    	&:first-child{
	    		*{
	    			text-align: left;
	    		}

	    		h5#highlight{
	    			font-size: 1.6em;
	    			color: #CB007B;
    				font-family: Montserrat-extrabold;
	    		}

	    		h1{
	    			font-family: 'Montserrat-ExtraBold';
				    display: block;
				    font-size: 3.2em;
				    text-align: left;

				    background: linear-gradient(90deg, #00ACD8, #CB007B);
				    color: transparent;
				    background-clip: text;
	    		}

	    		p{
	    			font-size: 12pt;
	    			padding-bottom: 2em;
	    		}

	    		ul{
	    			list-style: none;
				    padding: 0;
				    display: flex;
				    flex-direction: row;
				    flex-wrap: wrap;
				    align-content: center;
				    justify-content: center;
				    margin-bottom: 0;

				    li{
				    	width: 50%;
				    	padding: 1em;
				    	padding-left: 0;
				    	padding-bottom: 0;

				    	h5{
				    		font-family: Montserrat-bold;
				    		font-size: 1rem;
				    	}

				    	p{
				    		font-size: 11pt;
				    		padding-left: 45px;
				    		padding-bottom: 0;
				    	}
				    }
	    		}
	    	}

	    	&:last-child{

		    	img{
		    		width: 100%;
		    	}
	    	}
	    }

	    #spanDecoration{
	    	height: 1.3em;
			display: block;
			background-color: #00ACD8;
			width: 120px;
			margin-bottom: 2em;
	    }
	}

	#aboutVideo{
		min-height: 250px;
		background: linear-gradient(90deg, #00ACD8, #CB007B);
		display: flex;
	    flex-wrap: nowrap;
	    align-content: center;
	    justify-content: center;
	    align-items: center;
	    flex-direction: column;
	    padding: 4em 10vw;

	    h2{
	    	font-family: 'Montserrat-Bold';
	    	color: white;
	    	font-size: 35pt;
	    }

	    #miniGallery{
	    	.contentSlider{
	    		.slider-item{
	    			height: auto;
	    		}
	    	}
	    }

	}

	#howWorks{
		padding: 2em 12vw 2em;

		 h2{
	    	background: linear-gradient(90deg, #00ACD8, #CB007B);
		    color: transparent;
		    background-clip: text;
		    font-family: 'Montserrat-bold';
		    font-size: 35pt;
		    display: inline;
	    }

		div{
			display: flex;
		    flex-wrap: wrap;
		    justify-content: center;
		    align-content: center;
		    align-items: center;
		    flex-direction: row;

		    article{
		    	width: 33%;

		    	img{
		    		width: 40%;
		    		margin: 2em;
		    	}

		    	h4{
		    		font-family: 'Montserrat-bold';

		    	}

		    	p{
		    		padding-left: 2em;
		    		padding-right: 2em;
		    	}
		    }
		}
		

	    
	}

	.contentSlider{
		margin-top: 5em;
		color: black;

		.slider-item{
			background-color: #E8E8E8;
			color: black;
			width: 30%;
			margin-right: 5%;
			overflow: hidden;
			display: flex;
		    flex-wrap: nowrap;
		    flex-direction: column;
		    
			justify-content: flex-start;

			img{
				width: 100%;
			}

			h5{
				font-size: 12pt;
				font-family: 'Montserrat-regular';
				min-height: 60px;
				display: flex;
				align-items: center;
				margin: 0;

			}

			p{
				width: 100%;
				white-space: normal;
				padding: 0 0.75em 0;
				height: 130px;
			}
		}
	}

	#forYou{
		padding: 2em 14vw 2em;

		h2{
			font-family: 'Montserrat-bold';
			font-size: 35pt;
			background: linear-gradient(90deg, #00ACD8, #CB007B);
			color: transparent;
			background-clip: text;
			display: inline;
		}
	}

	#plans{
		padding: 2em 14vw 2em;

		h2{
			font-family: 'Montserrat-bold';
			font-size: 35pt;
			background: linear-gradient(90deg, #00ACD8, #CB007B);
			color: transparent;
			background-clip: text;
	
			display: inline-block;
    		padding: 1.5em;
		}

		span:first-child{
			display: block;
		}

		span#togglebutton{
			display: flex;
		    align-items: center;
		    justify-content: center;
		    align-content: center;
		    flex-wrap: nowrap;
		    margin: 4em;
		}

		.plansInfo{
			display: flex;

			article{
				margin: 2em;
				color: white;
				padding-top: 2em;
				background-color: #CB007B;

				&:first-child{
					background-color: #D8B500;
				}

				&:last-child{
					background-color: #00ACD8;
				}

				ul{
					background-color: #E7E7E7;
				}

				img{
					margin-bottom: 2em;
				}

				h3{
					font-family: 'Montserrat-regular';
					font-size: 1.7rem;
					margin-bottom: 1.2em;

					&:last-child{
						margin-bottom: 0.6em;
					}
				}

				ul{
					color: black;
					padding: 2em 4em;
    				min-height: 380px;

					li{
						text-align: left;
					}
				}
			}
		}
	}

	#faq{
		padding: 2em 14vw 2em;

		h2{
			font-family: 'Montserrat-bold';
			font-size: 35pt;
			background: linear-gradient(90deg, #00ACD8, #CB007B);
			color: transparent;
			background-clip: text;
			display: inline-block;
			padding-bottom: 0.7em;
		}

		div{
			display: flex;
		    flex-wrap: wrap;
		    flex-direction: row;
		    align-content: center;
		    align-items: flex-start;
		    padding-top: 4em;

		    article{
		    	width: 50%;
		    	padding: 0 2em;

		    	button{
		    		width: 100%;
				    background-color: #00acd8;
				    border: none;
				    padding: 1em 0;
				    color: white;
				    display: flex;
				    align-items: center;
				    align-content: center;
				    flex-direction: row;
				    justify-content: space-between;
				    padding-left: 2em;

				    span{
				    	fill: white;
				    	padding: 1em;
				    }
		    	}

		    	p{
		    		max-height: 0;

		    		overflow: hidden;
		    		transition: max-height 400ms ease-in-out 0ms;

		    		&.active{
		    			max-height: 600px;
		    			
		    		}
		    	}
		    }
		}
	}

	#stores{
		h2{
			font-family: 'Montserrat-bold';
			font-size: 35pt;
			background: linear-gradient(90deg, #00ACD8, #CB007B);
			color: transparent;
			background-clip: text;
			display: inline-block;
			margin: 2em 0;
		}

		ul{
			display: flex;
		    text-align: center;
		    flex-wrap: nowrap;
		    align-content: center;
		    justify-content: center;
		    align-items: center;
		    flex-direction: row;
		    list-style: none;

		    li{
		    	padding: 2em;
		    }
		}
	}

	#contact{
		padding: 2em 14vw 2em;
		background-color: #00acd8;

		h2{
			font-family: 'Montserrat-bold';
			font-size: 35pt;
			color: white;
			display: inline-block;
			margin: 2rem 0;
		}

		span{
			color: white;
			max-width: 700px;
			display: inline-block;
			padding-bottom: 2em;
		}

		form{

			display: flex;
		    flex-direction: row;
		    flex-wrap: wrap;
		    justify-content: center;
		    padding: 0 6rem;
    		font-size: 12pt;

		    div{
				width: 50%;

				&#msgbox{
					width: 100%;
				}

				input, textarea{
					width: 90%;
				    padding: 0.6em;
				    margin-bottom: 1em;
				    background-color: #d9d9d9;
				    border: none;
				    color: black;
				    text-align: left;
				    padding-left: 2em;
				}

				textarea{
					width: 95%;
					height: 150px;
					resize: none;
				}


			}

			button{
				background-color: #cb007b;
				border: none;
				color: white;
				padding: 1rem 2rem;
			}
		}
		

	
	}


}

@media (max-width: 1024px){
	#landingpage{

		.slider-item{
			overflow: hidden;

			img{
			    width: 200vw;
			    text-align: center;
			}

			
		}

		section#slider div.sliderCopy{
			h3{
			    font-size: 14pt;
			    padding: 0.2em 0.5em;
			    font-family: Montserrat-regular;
			}

			h2{
				font-family: Montserrat-medium;
				font-size: 0.8em;

				span{
					font-size: 1em;
					font-family: Montserrat-medium;
				}
			}
		}

		#about{
			padding: 8em 5em 0em 5em;
			flex-wrap: wrap;
			text-align: center;

			div{
				width: 100%;

				&:first-child{
					p,h1,h5,span{
						text-align: center;
					}

					h1{
						font-size: 3em;
					}

					#spanDecoration{
						display: inline-block;
					}

					ul{
						li{
							width: 100%;

							p,h5{
								text-align: left;
							}
						}
					}	
				}
			}
		}

		#aboutVideo{
			min-height: 250px;
			background: linear-gradient(90deg, #00ACD8, #CB007B);
			display: flex;
		    flex-wrap: nowrap;
		    align-content: center;
		    justify-content: center;
		    align-items: center;
		    flex-direction: column;
			padding: 2em;

		    h2{
		    	font-family: 'Montserrat-Bold';
		    	color: white;
		    	font-size: 20pt;
		    }

		}

		#howWorks{

			flex-direction: column;

			div{
				article{
					width: 100%;

					img{
						width: 200px;
					}
				}
			}
		}

		#forYou{
			.contentSlider{
				

				.slider-item{
					width: 100%;
					margin-right: 0;
				}
			}
		}

		span#togglebutton{
			font-size: 0.3em;
		}

		#plans{
			.plansInfo{
				flex-direction: column;

				article{
					margin: 2em 0 0 0;

					ul{
						min-height: auto;
						padding: 2em;
					}
				}
			}
		}

		#faq{
			div{
				article{
					width: 100%;
				}
			}
		}

		#stores{
			ul{
				flex-direction: column;
			}
		}

		#contact{
			form{
				padding: 0;

				div{
					width: 100%;

					input,textarea{
						width: 100%;
					}
				}
			}
		}
		
	}
}


.switch {
  --_switch-bg-clr: #d9d9d9;
  --_switch-padding: 4px; 
  --_slider-bg-clr: #00acd8; 
  --_slider-bg-clr-on: #00acd8; 
  --_slider-txt-clr: #ffffff;
  --_label-padding: 1rem 2rem;
  --_switch-easing: cubic-bezier(
    0.47,
    1.64,
    0.41,
    0.8
  ); 
  color: black;
  width: fit-content;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 9999px;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  position: relative;
  isolation: isolate;

}

.switch input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.switch > span {
  display: grid;
  place-content: center;
  transition: opacity 300ms ease-in-out 150ms;
  padding: var(--_label-padding);
  font-family: 'Montserrat-regular';
  font-size: 12pt;
}
.switch::before,
.switch::after {
  content: "";
  position: absolute;
  border-radius: inherit;
  transition: inset 150ms ease-in-out;
}
/* switch slider */
.switch::before {
  background-color: var(--_slider-bg-clr);
  inset: var(--_switch-padding) 50% var(--_switch-padding)
    var(--_switch-padding);
  transition:
    inset 500ms var(--_switch-easing),
    background-color 500ms ease-in-out;
  z-index: -1;

}
/* switch bg color */
.switch::after {
  background-color: var(--_switch-bg-clr);
  inset: 0;
  z-index: -2;
}
/* switch hover & focus */
.switch:focus-within::after {
  inset: -0.25rem;
}
.switch:has(input:checked):hover > span:first-of-type,
.switch:has(input:not(:checked)):hover > span:last-of-type {
  opacity: 1;
  transition-delay: 0ms;
  transition-duration: 100ms;
}
/* switch hover */
.switch:has(input:checked):hover::before {
  inset: var(--_switch-padding) var(--_switch-padding) var(--_switch-padding)
    45%;
}
.switch:has(input:not(:checked)):hover::before {
  inset: var(--_switch-padding) 45% var(--_switch-padding)
    var(--_switch-padding);
}
/* checked - move slider to right */
.switch:has(input:checked)::before {
  background-color: var(--_slider-bg-clr-on);
  inset: var(--_switch-padding) var(--_switch-padding) var(--_switch-padding)
    50%;
}
/* checked - set opacity */
.switch > span:last-of-type,
.switch > input:checked + span:first-of-type {
  opacity: 0.75;
}
.switch > input:checked ~ span:last-of-type {
  opacity: 1;
}
</style>